import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";

const AuthCallback = () => {
  const navigate = useNavigate();

  const auth = useAuth();
  useEffect(() => {
    auth.userManager.signinRedirectCallback().then((user) => {
      sessionStorage.setItem("access_token", user.access_token);
    }).catch(error => console.log(error))
     .finally(() =>  navigate("/dashboards"));
  }, []);

  return <SuspenseLoader />;
};
export default AuthCallback;
