import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// axios
import axios from 'axios';
import {
  academyApi,
  CreateEventRequest,
  CreateEventResponseResult,
  UpdateEventRequest,
  UpdateEventResponseResult
} from 'src/store/apiClient';

import { Status } from 'src/types/Status';
export interface EventState {
  status: Status;
  error: string | null;
}

const initialState: EventState = {
  status: 'idle',
  error: null
};


export const addNewEvent = createAsyncThunk<
  CreateEventResponseResult,
  CreateEventRequest
>('events/addNewEvent', async (initialAuthor) => {
  const response = await academyApi.createEvent(initialAuthor);
  return response.data;
});

export const updateEvent = createAsyncThunk<
  UpdateEventResponseResult,
  UpdateEventRequest
>('events/updateEvents', async (request) => {
  const response = await academyApi.updateEvent(request);
  return response.data;
});


export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(addNewEvent.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewEvent.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addNewEvent.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      })
      .addCase(updateEvent.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateEvent.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      });
  }
});

export default eventSlice.reducer;
