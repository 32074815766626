/* tslint:disable */
/* eslint-disable */
/**
 * Rest Of My Cloud - WebApi
 * A application with Swagger, Swashbuckle, and API versioning.
 *
 * The version of the OpenAPI document: 0.1.2 (dev)
 * Contact: hello@lautarocarro.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplyCategoryRequest
 */
export interface ApplyCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyCategoryRequest
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyCategoryRequest
     */
    'categoryId'?: string;
}
/**
 * 
 * @export
 * @interface ApplyRoleRequest
 */
export interface ApplyRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyRoleRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyRoleRequest
     */
    'role'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplyTagRequest
 */
export interface ApplyTagRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyTagRequest
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyTagRequest
     */
    'tagId'?: string;
}
/**
 * 
 * @export
 * @interface CreateAuthorRequest
 */
export interface CreateAuthorRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorRequest
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface CreateAuthorResponse
 */
export interface CreateAuthorResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'authorId'?: string;
    /**
     * 
     * @type {PostStatus}
     * @memberof CreateAuthorResponse
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthorResponse
     */
    'publishedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAuthorResponseResult
 */
export interface CreateAuthorResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAuthorResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAuthorResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {CreateAuthorResponse}
     * @memberof CreateAuthorResponseResult
     */
    'data'?: CreateAuthorResponse;
}
/**
 * 
 * @export
 * @interface CreateCategoryRequest
 */
export interface CreateCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCategoryResponse
 */
export interface CreateCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCategoryResponseResult
 */
export interface CreateCategoryResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCategoryResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCategoryResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {CreateCategoryResponse}
     * @memberof CreateCategoryResponseResult
     */
    'data'?: CreateCategoryResponse;
}
/**
 * 
 * @export
 * @interface CreateCommentRequest
 */
export interface CreateCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCommentRequest
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentRequest
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCommentResponse
 */
export interface CreateCommentResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCommentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentResponse
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommentResponse
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCommentResponseResult
 */
export interface CreateCommentResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCommentResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCommentResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {CreateCommentResponse}
     * @memberof CreateCommentResponseResult
     */
    'data'?: CreateCommentResponse;
}
/**
 * 
 * @export
 * @interface CreateEventRequest
 */
export interface CreateEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEventRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventRequest
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventRequest
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateEventResponse
 */
export interface CreateEventResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'authorId'?: string;
    /**
     * 
     * @type {PostStatus}
     * @memberof CreateEventResponse
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventResponse
     */
    'publishedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateEventResponseResult
 */
export interface CreateEventResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof CreateEventResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateEventResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {CreateEventResponse}
     * @memberof CreateEventResponseResult
     */
    'data'?: CreateEventResponse;
}
/**
 * 
 * @export
 * @interface CreatePostRequest
 */
export interface CreatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequest
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequest
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePostResponse
 */
export interface CreatePostResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'authorId'?: string;
    /**
     * 
     * @type {PostStatus}
     * @memberof CreatePostResponse
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'publishedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponse
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePostResponseResult
 */
export interface CreatePostResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePostResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePostResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {CreatePostResponse}
     * @memberof CreatePostResponseResult
     */
    'data'?: CreatePostResponse;
}
/**
 * 
 * @export
 * @interface CreateSubscriberRequest
 */
export interface CreateSubscriberRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriberRequest
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateTagRequest
 */
export interface CreateTagRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateTagResponse
 */
export interface CreateTagResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateTagResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTagResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTagResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTagResponse
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateTagResponseResult
 */
export interface CreateTagResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof CreateTagResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTagResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {CreateTagResponse}
     * @memberof CreateTagResponseResult
     */
    'data'?: CreateTagResponse;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'birthDate'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    'birthDate'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserResponseResult
 */
export interface CreateUserResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {CreateUserResponse}
     * @memberof CreateUserResponseResult
     */
    'data'?: CreateUserResponse;
}
/**
 * 
 * @export
 * @interface ForgotPasswordConfirmationRequest
 */
export interface ForgotPasswordConfirmationRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordConfirmationRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordConfirmationRequest
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordConfirmationRequest
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAllRolesResponse
 */
export interface GetAllRolesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAllRolesResponse
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetAllRolesResponseResult
 */
export interface GetAllRolesResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetAllRolesResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAllRolesResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {GetAllRolesResponse}
     * @memberof GetAllRolesResponseResult
     */
    'data'?: GetAllRolesResponse;
}
/**
 * 
 * @export
 * @interface GetAllUserItem
 */
export interface GetAllUserItem {
    /**
     * 
     * @type {string}
     * @memberof GetAllUserItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllUserItem
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllUserItem
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllUserItem
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAllUsersResponse
 */
export interface GetAllUsersResponse {
    /**
     * 
     * @type {Array<GetAllUserItem>}
     * @memberof GetAllUsersResponse
     */
    'items'?: Array<GetAllUserItem> | null;
    /**
     * 
     * @type {number}
     * @memberof GetAllUsersResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetAllUsersResponseResult
 */
export interface GetAllUsersResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetAllUsersResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAllUsersResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {GetAllUsersResponse}
     * @memberof GetAllUsersResponseResult
     */
    'data'?: GetAllUsersResponse;
}
/**
 * 
 * @export
 * @interface GetCategoryByIdItem
 */
export interface GetCategoryByIdItem {
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdItem
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdItem
     */
    'slug'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCategoryByIdResponse
 */
export interface GetCategoryByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryByIdResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {GetCategoryByIdItem}
     * @memberof GetCategoryByIdResponse
     */
    'parent'?: GetCategoryByIdItem;
    /**
     * 
     * @type {Array<PostItemDto>}
     * @memberof GetCategoryByIdResponse
     */
    'posts'?: Array<PostItemDto> | null;
}
/**
 * 
 * @export
 * @interface GetCategoryByIdResponseResult
 */
export interface GetCategoryByIdResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetCategoryByIdResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCategoryByIdResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {GetCategoryByIdResponse}
     * @memberof GetCategoryByIdResponseResult
     */
    'data'?: GetCategoryByIdResponse;
}
/**
 * 
 * @export
 * @interface GetCommentsByPostResponse
 */
export interface GetCommentsByPostResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCommentsByPostResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommentsByPostResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommentsByPostResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommentsByPostResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommentsByPostResponse
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommentsByPostResponseIEnumerableResult
 */
export interface GetCommentsByPostResponseIEnumerableResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommentsByPostResponseIEnumerableResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCommentsByPostResponseIEnumerableResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {Array<GetCommentsByPostResponse>}
     * @memberof GetCommentsByPostResponseIEnumerableResult
     */
    'data'?: Array<GetCommentsByPostResponse> | null;
}
/**
 * 
 * @export
 * @interface GetPostByIdResponse
 */
export interface GetPostByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'authorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'publishedAt'?: string | null;
    /**
     * 
     * @type {PostStatus}
     * @memberof GetPostByIdResponse
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {PostType}
     * @memberof GetPostByIdResponse
     */
    'type'?: PostType;
    /**
     * 
     * @type {string}
     * @memberof GetPostByIdResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<TitleSlugItem>}
     * @memberof GetPostByIdResponse
     */
    'categories'?: Array<TitleSlugItem> | null;
    /**
     * 
     * @type {Array<TitleSlugItem>}
     * @memberof GetPostByIdResponse
     */
    'tags'?: Array<TitleSlugItem> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetPostByIdResponse
     */
    'metas'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface GetPostByIdResponseResult
 */
export interface GetPostByIdResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetPostByIdResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPostByIdResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {GetPostByIdResponse}
     * @memberof GetPostByIdResponseResult
     */
    'data'?: GetPostByIdResponse;
}
/**
 * 
 * @export
 * @interface GetTagByIdResponse
 */
export interface GetTagByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTagByIdResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTagByIdResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTagByIdResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTagByIdResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTagByIdResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {Array<PostItemDto>}
     * @memberof GetTagByIdResponse
     */
    'posts'?: Array<PostItemDto> | null;
}
/**
 * 
 * @export
 * @interface GetTagByIdResponseResult
 */
export interface GetTagByIdResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetTagByIdResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTagByIdResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {GetTagByIdResponse}
     * @memberof GetTagByIdResponseResult
     */
    'data'?: GetTagByIdResponse;
}
/**
 * 
 * @export
 * @interface GetUserByIdResponse
 */
export interface GetUserByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof GetUserByIdResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserByIdResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetUserByIdResponse
     */
    'claims'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserByIdResponse
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetUserByIdResponseResult
 */
export interface GetUserByIdResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserByIdResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserByIdResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {GetUserByIdResponse}
     * @memberof GetUserByIdResponseResult
     */
    'data'?: GetUserByIdResponse;
}
/**
 * 
 * @export
 * @interface GetWebsiteMetaTagsRequest
 */
export interface GetWebsiteMetaTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWebsiteMetaTagsRequest
     */
    'websites'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetWebsiteMetaTagsResponse
 */
export interface GetWebsiteMetaTagsResponse {
    /**
     * 
     * @type {Array<Website>}
     * @memberof GetWebsiteMetaTagsResponse
     */
    'websites'?: Array<Website> | null;
}
/**
 * 
 * @export
 * @interface GetWebsiteMetaTagsResponseResult
 */
export interface GetWebsiteMetaTagsResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof GetWebsiteMetaTagsResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWebsiteMetaTagsResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {GetWebsiteMetaTagsResponse}
     * @memberof GetWebsiteMetaTagsResponseResult
     */
    'data'?: GetWebsiteMetaTagsResponse;
}
/**
 * 
 * @export
 * @interface PostItemDto
 */
export interface PostItemDto {
    /**
     * 
     * @type {string}
     * @memberof PostItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostItemDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostItemDto
     */
    'slug'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PostStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type PostStatus = typeof PostStatus[keyof typeof PostStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const PostType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PostType = typeof PostType[keyof typeof PostType];


/**
 * 
 * @export
 * @interface RemoveRoleRequest
 */
export interface RemoveRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveRoleRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveRoleRequest
     */
    'role'?: string | null;
}
/**
 * 
 * @export
 * @interface ResetUserPasswordRequest
 */
export interface ResetUserPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordRequest
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface ResetUserPasswordResponse
 */
export interface ResetUserPasswordResponse {
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ResetUserPasswordResponseResult
 */
export interface ResetUserPasswordResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof ResetUserPasswordResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResetUserPasswordResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {ResetUserPasswordResponse}
     * @memberof ResetUserPasswordResponseResult
     */
    'data'?: ResetUserPasswordResponse;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Result
     */
    'errors'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SearchCategoriesResponse
 */
export interface SearchCategoriesResponse {
    /**
     * 
     * @type {Array<SearchCategoryItem>}
     * @memberof SearchCategoriesResponse
     */
    'items'?: Array<SearchCategoryItem> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchCategoriesResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SearchCategoriesResponseResult
 */
export interface SearchCategoriesResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof SearchCategoriesResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchCategoriesResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {SearchCategoriesResponse}
     * @memberof SearchCategoriesResponseResult
     */
    'data'?: SearchCategoriesResponse;
}
/**
 * 
 * @export
 * @interface SearchCategoryItem
 */
export interface SearchCategoryItem {
    /**
     * 
     * @type {string}
     * @memberof SearchCategoryItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCategoryItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchCategoryItem
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchCategoryItem
     */
    'slug'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchCategoryItem
     */
    'postCount'?: number;
    /**
     * 
     * @type {SearchCategoryItem}
     * @memberof SearchCategoryItem
     */
    'parent'?: SearchCategoryItem;
}
/**
 * 
 * @export
 * @interface SearchPostItem
 */
export interface SearchPostItem {
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'authorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'publishedAt'?: string | null;
    /**
     * 
     * @type {PostStatus}
     * @memberof SearchPostItem
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {string}
     * @memberof SearchPostItem
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<TitleSlugItem>}
     * @memberof SearchPostItem
     */
    'categories'?: Array<TitleSlugItem> | null;
    /**
     * 
     * @type {Array<TitleSlugItem>}
     * @memberof SearchPostItem
     */
    'tags'?: Array<TitleSlugItem> | null;
}
/**
 * 
 * @export
 * @interface SearchPostsResponse
 */
export interface SearchPostsResponse {
    /**
     * 
     * @type {Array<SearchPostItem>}
     * @memberof SearchPostsResponse
     */
    'items'?: Array<SearchPostItem> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchPostsResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SearchPostsResponseResult
 */
export interface SearchPostsResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof SearchPostsResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchPostsResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {SearchPostsResponse}
     * @memberof SearchPostsResponseResult
     */
    'data'?: SearchPostsResponse;
}
/**
 * 
 * @export
 * @interface SearchTagsItem
 */
export interface SearchTagsItem {
    /**
     * 
     * @type {string}
     * @memberof SearchTagsItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTagsItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchTagsItem
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchTagsItem
     */
    'slug'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchTagsItem
     */
    'postCount'?: number;
}
/**
 * 
 * @export
 * @interface SearchTagsResponse
 */
export interface SearchTagsResponse {
    /**
     * 
     * @type {Array<SearchTagsItem>}
     * @memberof SearchTagsResponse
     */
    'items'?: Array<SearchTagsItem> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchTagsResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SearchTagsResponseResult
 */
export interface SearchTagsResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagsResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTagsResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {SearchTagsResponse}
     * @memberof SearchTagsResponseResult
     */
    'data'?: SearchTagsResponse;
}
/**
 * 
 * @export
 * @interface TitleSlugItem
 */
export interface TitleSlugItem {
    /**
     * 
     * @type {string}
     * @memberof TitleSlugItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleSlugItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TitleSlugItem
     */
    'slug'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAuthorRequest
 */
export interface UpdateAuthorRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'github'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'twitter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorRequest
     */
    'linkedin'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAuthorResponse
 */
export interface UpdateAuthorResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'github'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'twitter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthorResponse
     */
    'linkedin'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAuthorResponseResult
 */
export interface UpdateAuthorResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAuthorResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAuthorResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UpdateAuthorResponse}
     * @memberof UpdateAuthorResponseResult
     */
    'data'?: UpdateAuthorResponse;
}
/**
 * 
 * @export
 * @interface UpdateCategoryRequest
 */
export interface UpdateCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryRequest
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCategoryResponse
 */
export interface UpdateCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryResponse
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCategoryResponseResult
 */
export interface UpdateCategoryResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCategoryResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCategoryResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UpdateCategoryResponse}
     * @memberof UpdateCategoryResponseResult
     */
    'data'?: UpdateCategoryResponse;
}
/**
 * 
 * @export
 * @interface UpdateEventRequest
 */
export interface UpdateEventRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {PostStatus}
     * @memberof UpdateEventRequest
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'publishedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'github'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'meetup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'speakerDeck'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'youtube'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventRequest
     */
    'website'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateEventResponse
 */
export interface UpdateEventResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'postId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'github'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'meetup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'speakerDeck'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'youtube'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventResponse
     */
    'website'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateEventResponseResult
 */
export interface UpdateEventResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEventResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEventResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UpdateEventResponse}
     * @memberof UpdateEventResponseResult
     */
    'data'?: UpdateEventResponse;
}
/**
 * 
 * @export
 * @interface UpdatePostRequest
 */
export interface UpdatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'authorId'?: string;
    /**
     * 
     * @type {PostStatus}
     * @memberof UpdatePostRequest
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'publishedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostRequest
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePostResponse
 */
export interface UpdatePostResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'summary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'authorId'?: string;
    /**
     * 
     * @type {PostStatus}
     * @memberof UpdatePostResponse
     */
    'status'?: PostStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'publishedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePostResponse
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePostResponseResult
 */
export interface UpdatePostResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePostResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePostResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UpdatePostResponse}
     * @memberof UpdatePostResponseResult
     */
    'data'?: UpdatePostResponse;
}
/**
 * 
 * @export
 * @interface UpdateTagRequest
 */
export interface UpdateTagRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagRequest
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagRequest
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagRequest
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateTagResponse
 */
export interface UpdateTagResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagResponse
     */
    'metaTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagResponse
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTagResponse
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateTagResponseResult
 */
export interface UpdateTagResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTagResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UpdateTagResponse}
     * @memberof UpdateTagResponseResult
     */
    'data'?: UpdateTagResponse;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'birthDate'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserResponse
 */
export interface UpdateUserResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserResponse
     */
    'birthDate'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserResponseResult
 */
export interface UpdateUserResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UpdateUserResponse}
     * @memberof UpdateUserResponseResult
     */
    'data'?: UpdateUserResponse;
}
/**
 * 
 * @export
 * @interface UploadFeatureImageResponse
 */
export interface UploadFeatureImageResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFeatureImageResponse
     */
    'image'?: string | null;
}
/**
 * 
 * @export
 * @interface UploadFeatureImageResponseResult
 */
export interface UploadFeatureImageResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UploadFeatureImageResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadFeatureImageResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UploadFeatureImageResponse}
     * @memberof UploadFeatureImageResponseResult
     */
    'data'?: UploadFeatureImageResponse;
}
/**
 * 
 * @export
 * @interface UploadImageResponse
 */
export interface UploadImageResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    'image'?: string | null;
}
/**
 * 
 * @export
 * @interface UploadImageResponseResult
 */
export interface UploadImageResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UploadImageResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadImageResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UploadImageResponse}
     * @memberof UploadImageResponseResult
     */
    'data'?: UploadImageResponse;
}
/**
 * 
 * @export
 * @interface UserChangePasswordRequest
 */
export interface UserChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'oldPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLoginResponse
 */
export interface UserLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponse
     */
    'accessToken'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLoginResponseResult
 */
export interface UserLoginResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof UserLoginResponseResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserLoginResponseResult
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {UserLoginResponse}
     * @memberof UserLoginResponseResult
     */
    'data'?: UserLoginResponse;
}
/**
 * 
 * @export
 * @interface VerifyEmailRequest
 */
export interface VerifyEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequest
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface Website
 */
export interface Website {
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    'imageLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    'link'?: string | null;
}

/**
 * AcademyApiApi - axios parameter creator
 * @export
 */
export const AcademyApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplyCategoryRequest} applyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCategory: async (applyCategoryRequest: ApplyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyCategoryRequest' is not null or undefined
            assertParamExists('applyCategory', 'applyCategoryRequest', applyCategoryRequest)
            const localVarPath = `/Posts/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplyRoleRequest} applyRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyRole: async (applyRoleRequest: ApplyRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyRoleRequest' is not null or undefined
            assertParamExists('applyRole', 'applyRoleRequest', applyRoleRequest)
            const localVarPath = `/Users/AddRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplyTagRequest} applyTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyTag: async (applyTagRequest: ApplyTagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyTagRequest' is not null or undefined
            assertParamExists('applyTag', 'applyTagRequest', applyTagRequest)
            const localVarPath = `/Posts/Tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAuthorRequest} createAuthorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthor: async (createAuthorRequest: CreateAuthorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthorRequest' is not null or undefined
            assertParamExists('createAuthor', 'createAuthorRequest', createAuthorRequest)
            const localVarPath = `/Authors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuthorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCategoryRequest} createCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (createCategoryRequest: CreateCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategoryRequest' is not null or undefined
            assertParamExists('createCategory', 'createCategoryRequest', createCategoryRequest)
            const localVarPath = `/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCommentRequest} createCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (createCommentRequest: CreateCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommentRequest' is not null or undefined
            assertParamExists('createComment', 'createCommentRequest', createCommentRequest)
            const localVarPath = `/Comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent: async (createEventRequest: CreateEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventRequest' is not null or undefined
            assertParamExists('createEvent', 'createEventRequest', createEventRequest)
            const localVarPath = `/Events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost: async (createPostRequest: CreatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPostRequest' is not null or undefined
            assertParamExists('createPost', 'createPostRequest', createPostRequest)
            const localVarPath = `/Posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSubscriberRequest} createSubscriberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriber: async (createSubscriberRequest: CreateSubscriberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSubscriberRequest' is not null or undefined
            assertParamExists('createSubscriber', 'createSubscriberRequest', createSubscriberRequest)
            const localVarPath = `/Subscribers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubscriberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTagRequest} createTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (createTagRequest: CreateTagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagRequest' is not null or undefined
            assertParamExists('createTag', 'createTagRequest', createTagRequest)
            const localVarPath = `/Tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/Categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteComment', 'id', id)
            const localVarPath = `/Comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePost', 'id', id)
            const localVarPath = `/Posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTag', 'id', id)
            const localVarPath = `/Tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest: ForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordRequest', forgotPasswordRequest)
            const localVarPath = `/Users/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordConfirmationRequest} forgotPasswordConfirmationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordConfirmation: async (forgotPasswordConfirmationRequest: ForgotPasswordConfirmationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordConfirmationRequest' is not null or undefined
            assertParamExists('forgotPasswordConfirmation', 'forgotPasswordConfirmationRequest', forgotPasswordConfirmationRequest)
            const localVarPath = `/Users/ForgotPassword/Confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordConfirmationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategoryById', 'id', id)
            const localVarPath = `/Categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByPost: async (postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('getCommentsByPost', 'postId', postId)
            const localVarPath = `/Comments/Posts/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPostById', 'id', id)
            const localVarPath = `/Posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagById', 'id', id)
            const localVarPath = `/Tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetWebsiteMetaTagsRequest} getWebsiteMetaTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsiteMetaTags: async (getWebsiteMetaTagsRequest: GetWebsiteMetaTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getWebsiteMetaTagsRequest' is not null or undefined
            assertParamExists('getWebsiteMetaTags', 'getWebsiteMetaTagsRequest', getWebsiteMetaTagsRequest)
            const localVarPath = `/Tools/WebsitesMetatags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getWebsiteMetaTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCategory: async (postId: string, categoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('removeCategory', 'postId', postId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('removeCategory', 'categoryId', categoryId)
            const localVarPath = `/Posts/{postId}/Categories/{categoryId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveRoleRequest} removeRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRole: async (removeRoleRequest: RemoveRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeRoleRequest' is not null or undefined
            assertParamExists('removeRole', 'removeRoleRequest', removeRoleRequest)
            const localVarPath = `/Users/RemoveRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag: async (postId: string, tagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('removeTag', 'postId', postId)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('removeTag', 'tagId', tagId)
            const localVarPath = `/Posts/{postId}/Tags/{tagId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetUserPasswordRequest} resetUserPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword: async (resetUserPasswordRequest: ResetUserPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetUserPasswordRequest' is not null or undefined
            assertParamExists('resetUserPassword', 'resetUserPasswordRequest', resetUserPasswordRequest)
            const localVarPath = `/Users/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetUserPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCategories: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {string} [type] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPosts: async (search?: string, status?: string, type?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTags: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestMail: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendTestMail', 'body', body)
            const localVarPath = `/IntegrationTests/SendTestMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAuthorRequest} updateAuthorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthor: async (updateAuthorRequest: UpdateAuthorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAuthorRequest' is not null or undefined
            assertParamExists('updateAuthor', 'updateAuthorRequest', updateAuthorRequest)
            const localVarPath = `/Authors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuthorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCategoryRequest} updateCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (updateCategoryRequest: UpdateCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCategoryRequest' is not null or undefined
            assertParamExists('updateCategory', 'updateCategoryRequest', updateCategoryRequest)
            const localVarPath = `/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: async (updateEventRequest: UpdateEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEventRequest' is not null or undefined
            assertParamExists('updateEvent', 'updateEventRequest', updateEventRequest)
            const localVarPath = `/Events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePostRequest} updatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost: async (updatePostRequest: UpdatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePostRequest' is not null or undefined
            assertParamExists('updatePost', 'updatePostRequest', updatePostRequest)
            const localVarPath = `/Posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTagRequest} updateTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (updateTagRequest: UpdateTagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTagRequest' is not null or undefined
            assertParamExists('updateTag', 'updateTagRequest', updateTagRequest)
            const localVarPath = `/Tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFeatureImage: async (id: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadFeatureImage', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFeatureImage', 'file', file)
            const localVarPath = `/Posts/{id}/FeatureImage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadImage', 'file', file)
            const localVarPath = `/Posts/UploadImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserChangePasswordRequest} userChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userChangePassword: async (userChangePasswordRequest: UserChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userChangePasswordRequest' is not null or undefined
            assertParamExists('userChangePassword', 'userChangePasswordRequest', userChangePasswordRequest)
            const localVarPath = `/Users/UpdatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin: async (userLoginRequest: UserLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userLoginRequest' is not null or undefined
            assertParamExists('userLogin', 'userLoginRequest', userLoginRequest)
            const localVarPath = `/Users/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (verifyEmailRequest: VerifyEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailRequest' is not null or undefined
            assertParamExists('verifyEmail', 'verifyEmailRequest', verifyEmailRequest)
            const localVarPath = `/Users/VerifyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AcademyApiApi - functional programming interface
 * @export
 */
export const AcademyApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AcademyApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplyCategoryRequest} applyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyCategory(applyCategoryRequest: ApplyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyCategory(applyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplyRoleRequest} applyRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyRole(applyRoleRequest: ApplyRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyRole(applyRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplyTagRequest} applyTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyTag(applyTagRequest: ApplyTagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyTag(applyTagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAuthorRequest} createAuthorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthor(createAuthorRequest: CreateAuthorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAuthorResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthor(createAuthorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCategoryRequest} createCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(createCategoryRequest: CreateCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCategoryResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(createCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCommentRequest} createCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(createCommentRequest: CreateCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCommentResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(createCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvent(createEventRequest: CreateEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateEventResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(createEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPost(createPostRequest: CreatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePostResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPost(createPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSubscriberRequest} createSubscriberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriber(createSubscriberRequest: CreateSubscriberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriber(createSubscriberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateTagRequest} createTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(createTagRequest: CreateTagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTagResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(createTagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordConfirmationRequest} forgotPasswordConfirmationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPasswordConfirmation(forgotPasswordConfirmationRequest: ForgotPasswordConfirmationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPasswordConfirmation(forgotPasswordConfirmationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllRolesResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllUsersResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategoryByIdResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByPost(postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommentsByPostResponseIEnumerableResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByPost(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPostByIdResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTagByIdResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserByIdResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetWebsiteMetaTagsRequest} getWebsiteMetaTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebsiteMetaTags(getWebsiteMetaTagsRequest: GetWebsiteMetaTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWebsiteMetaTagsResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebsiteMetaTags(getWebsiteMetaTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCategory(postId: string, categoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCategory(postId, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveRoleRequest} removeRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRole(removeRoleRequest: RemoveRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRole(removeRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTag(postId: string, tagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTag(postId, tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetUserPasswordRequest} resetUserPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPassword(resetUserPasswordRequest: ResetUserPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetUserPasswordResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPassword(resetUserPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCategories(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCategoriesResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCategories(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {string} [type] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPosts(search?: string, status?: string, type?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPostsResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPosts(search, status, type, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTags(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchTagsResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTags(search, take, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestMail(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestMail(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAuthorRequest} updateAuthorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuthor(updateAuthorRequest: UpdateAuthorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAuthorResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuthor(updateAuthorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCategoryRequest} updateCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(updateCategoryRequest: UpdateCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCategoryResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(updateCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvent(updateEventRequest: UpdateEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateEventResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(updateEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePostRequest} updatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePost(updatePostRequest: UpdatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePostResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePost(updatePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTagRequest} updateTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(updateTagRequest: UpdateTagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTagResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(updateTagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFeatureImage(id: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFeatureImageResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFeatureImage(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserChangePasswordRequest} userChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userChangePassword(userChangePasswordRequest: UserChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userChangePassword(userChangePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLogin(userLoginRequest: UserLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLogin(userLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(verifyEmailRequest: VerifyEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(verifyEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AcademyApiApi - factory interface
 * @export
 */
export const AcademyApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AcademyApiApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplyCategoryRequest} applyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCategory(applyCategoryRequest: ApplyCategoryRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.applyCategory(applyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplyRoleRequest} applyRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyRole(applyRoleRequest: ApplyRoleRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.applyRole(applyRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplyTagRequest} applyTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyTag(applyTagRequest: ApplyTagRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.applyTag(applyTagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAuthorRequest} createAuthorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthor(createAuthorRequest: CreateAuthorRequest, options?: any): AxiosPromise<CreateAuthorResponseResult> {
            return localVarFp.createAuthor(createAuthorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCategoryRequest} createCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(createCategoryRequest: CreateCategoryRequest, options?: any): AxiosPromise<CreateCategoryResponseResult> {
            return localVarFp.createCategory(createCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCommentRequest} createCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(createCommentRequest: CreateCommentRequest, options?: any): AxiosPromise<CreateCommentResponseResult> {
            return localVarFp.createComment(createCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEventRequest} createEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(createEventRequest: CreateEventRequest, options?: any): AxiosPromise<CreateEventResponseResult> {
            return localVarFp.createEvent(createEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost(createPostRequest: CreatePostRequest, options?: any): AxiosPromise<CreatePostResponseResult> {
            return localVarFp.createPost(createPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSubscriberRequest} createSubscriberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriber(createSubscriberRequest: CreateSubscriberRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.createSubscriber(createSubscriberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTagRequest} createTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(createTagRequest: CreateTagRequest, options?: any): AxiosPromise<CreateTagResponseResult> {
            return localVarFp.createTag(createTagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<CreateUserResponseResult> {
            return localVarFp.createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: string, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(id: string, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteComment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost(id: string, options?: any): AxiosPromise<Result> {
            return localVarFp.deletePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(id: string, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<Result> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordConfirmationRequest} forgotPasswordConfirmationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordConfirmation(forgotPasswordConfirmationRequest: ForgotPasswordConfirmationRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.forgotPasswordConfirmation(forgotPasswordConfirmationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles(options?: any): AxiosPromise<GetAllRolesResponseResult> {
            return localVarFp.getAllRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<GetAllUsersResponseResult> {
            return localVarFp.getAllUsers(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById(id: string, options?: any): AxiosPromise<GetCategoryByIdResponseResult> {
            return localVarFp.getCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByPost(postId: string, options?: any): AxiosPromise<GetCommentsByPostResponseIEnumerableResult> {
            return localVarFp.getCommentsByPost(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostById(id: string, options?: any): AxiosPromise<GetPostByIdResponseResult> {
            return localVarFp.getPostById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById(id: string, options?: any): AxiosPromise<GetTagByIdResponseResult> {
            return localVarFp.getTagById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<GetUserByIdResponseResult> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetWebsiteMetaTagsRequest} getWebsiteMetaTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsiteMetaTags(getWebsiteMetaTagsRequest: GetWebsiteMetaTagsRequest, options?: any): AxiosPromise<GetWebsiteMetaTagsResponseResult> {
            return localVarFp.getWebsiteMetaTags(getWebsiteMetaTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCategory(postId: string, categoryId: string, options?: any): AxiosPromise<Result> {
            return localVarFp.removeCategory(postId, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveRoleRequest} removeRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRole(removeRoleRequest: RemoveRoleRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.removeRole(removeRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag(postId: string, tagId: string, options?: any): AxiosPromise<Result> {
            return localVarFp.removeTag(postId, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetUserPasswordRequest} resetUserPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(resetUserPasswordRequest: ResetUserPasswordRequest, options?: any): AxiosPromise<ResetUserPasswordResponseResult> {
            return localVarFp.resetUserPassword(resetUserPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCategories(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<SearchCategoriesResponseResult> {
            return localVarFp.searchCategories(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {string} [type] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPosts(search?: string, status?: string, type?: string, take?: number, skip?: number, options?: any): AxiosPromise<SearchPostsResponseResult> {
            return localVarFp.searchPosts(search, status, type, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTags(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<SearchTagsResponseResult> {
            return localVarFp.searchTags(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestMail(body: object, options?: any): AxiosPromise<Result> {
            return localVarFp.sendTestMail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAuthorRequest} updateAuthorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthor(updateAuthorRequest: UpdateAuthorRequest, options?: any): AxiosPromise<UpdateAuthorResponseResult> {
            return localVarFp.updateAuthor(updateAuthorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCategoryRequest} updateCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(updateCategoryRequest: UpdateCategoryRequest, options?: any): AxiosPromise<UpdateCategoryResponseResult> {
            return localVarFp.updateCategory(updateCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateEventRequest} updateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(updateEventRequest: UpdateEventRequest, options?: any): AxiosPromise<UpdateEventResponseResult> {
            return localVarFp.updateEvent(updateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePostRequest} updatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost(updatePostRequest: UpdatePostRequest, options?: any): AxiosPromise<UpdatePostResponseResult> {
            return localVarFp.updatePost(updatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTagRequest} updateTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(updateTagRequest: UpdateTagRequest, options?: any): AxiosPromise<UpdateTagResponseResult> {
            return localVarFp.updateTag(updateTagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UpdateUserResponseResult> {
            return localVarFp.updateUser(updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFeatureImage(id: string, file: File, options?: any): AxiosPromise<UploadFeatureImageResponseResult> {
            return localVarFp.uploadFeatureImage(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(file: File, options?: any): AxiosPromise<UploadImageResponseResult> {
            return localVarFp.uploadImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserChangePasswordRequest} userChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userChangePassword(userChangePasswordRequest: UserChangePasswordRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.userChangePassword(userChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin(userLoginRequest: UserLoginRequest, options?: any): AxiosPromise<UserLoginResponseResult> {
            return localVarFp.userLogin(userLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(verifyEmailRequest: VerifyEmailRequest, options?: any): AxiosPromise<Result> {
            return localVarFp.verifyEmail(verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionGet(options?: any): AxiosPromise<void> {
            return localVarFp.versionGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AcademyApiApi - object-oriented interface
 * @export
 * @class AcademyApiApi
 * @extends {BaseAPI}
 */
export class AcademyApiApi extends BaseAPI {
    /**
     * 
     * @param {ApplyCategoryRequest} applyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public applyCategory(applyCategoryRequest: ApplyCategoryRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).applyCategory(applyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplyRoleRequest} applyRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public applyRole(applyRoleRequest: ApplyRoleRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).applyRole(applyRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplyTagRequest} applyTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public applyTag(applyTagRequest: ApplyTagRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).applyTag(applyTagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAuthorRequest} createAuthorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createAuthor(createAuthorRequest: CreateAuthorRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createAuthor(createAuthorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCategoryRequest} createCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createCategory(createCategoryRequest: CreateCategoryRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createCategory(createCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCommentRequest} createCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createComment(createCommentRequest: CreateCommentRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createComment(createCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEventRequest} createEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createEvent(createEventRequest: CreateEventRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createEvent(createEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePostRequest} createPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createPost(createPostRequest: CreatePostRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createPost(createPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSubscriberRequest} createSubscriberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createSubscriber(createSubscriberRequest: CreateSubscriberRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createSubscriber(createSubscriberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTagRequest} createTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createTag(createTagRequest: CreateTagRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createTag(createTagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).createUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public deleteCategory(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).deleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public deleteComment(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).deleteComment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public deletePost(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).deletePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public deleteTag(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).deleteTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordRequest} forgotPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).forgotPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordConfirmationRequest} forgotPasswordConfirmationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public forgotPasswordConfirmation(forgotPasswordConfirmationRequest: ForgotPasswordConfirmationRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).forgotPasswordConfirmation(forgotPasswordConfirmationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getAllRoles(options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getAllRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getAllUsers(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getAllUsers(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getCategoryById(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getCommentsByPost(postId: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getCommentsByPost(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getPostById(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getPostById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getTagById(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getTagById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getUserById(id: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetWebsiteMetaTagsRequest} getWebsiteMetaTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public getWebsiteMetaTags(getWebsiteMetaTagsRequest: GetWebsiteMetaTagsRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).getWebsiteMetaTags(getWebsiteMetaTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public removeCategory(postId: string, categoryId: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).removeCategory(postId, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveRoleRequest} removeRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public removeRole(removeRoleRequest: RemoveRoleRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).removeRole(removeRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {string} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public removeTag(postId: string, tagId: string, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).removeTag(postId, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetUserPasswordRequest} resetUserPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public resetUserPassword(resetUserPasswordRequest: ResetUserPasswordRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).resetUserPassword(resetUserPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public searchCategories(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).searchCategories(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {string} [status] 
     * @param {string} [type] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public searchPosts(search?: string, status?: string, type?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).searchPosts(search, status, type, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public searchTags(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).searchTags(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public sendTestMail(body: object, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).sendTestMail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAuthorRequest} updateAuthorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public updateAuthor(updateAuthorRequest: UpdateAuthorRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).updateAuthor(updateAuthorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCategoryRequest} updateCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public updateCategory(updateCategoryRequest: UpdateCategoryRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).updateCategory(updateCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateEventRequest} updateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public updateEvent(updateEventRequest: UpdateEventRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).updateEvent(updateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePostRequest} updatePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public updatePost(updatePostRequest: UpdatePostRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).updatePost(updatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTagRequest} updateTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public updateTag(updateTagRequest: UpdateTagRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).updateTag(updateTagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public updateUser(updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).updateUser(updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public uploadFeatureImage(id: string, file: File, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).uploadFeatureImage(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public uploadImage(file: File, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).uploadImage(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserChangePasswordRequest} userChangePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public userChangePassword(userChangePasswordRequest: UserChangePasswordRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).userChangePassword(userChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserLoginRequest} userLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public userLogin(userLoginRequest: UserLoginRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).userLogin(userLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailRequest} verifyEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public verifyEmail(verifyEmailRequest: VerifyEmailRequest, options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).verifyEmail(verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcademyApiApi
     */
    public versionGet(options?: AxiosRequestConfig) {
        return AcademyApiApiFp(this.configuration).versionGet(options).then((request) => request(this.axios, this.basePath));
    }
}


