import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserLoginRequest,
  UserLoginResponse,
  UserLoginResponseResult
} from 'src/generated-sources/openapi';
import { academyApi } from 'src/store/apiClient';
import { Status } from 'src/types/Status';
export interface CategoryState {
  isAuthenticated: boolean;
  accessToken: string;
  status: Status;
  error: string | null;
}

const initialState: CategoryState = {
  isAuthenticated: !!localStorage.getItem('access_token'),
  accessToken: localStorage.getItem('access_token'),
  status: 'idle',
  error: null
};

export const login = createAsyncThunk<
  UserLoginResponseResult,
  UserLoginRequest
>('auth/login', async (request) => {
  const response = await academyApi.userLogin(request);
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      state.isAuthenticated = true;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(login.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.isAuthenticated = true;
        state.accessToken = action.payload.data.accessToken;
        localStorage.setItem('access_token', action.payload.data.accessToken);
      });
  }
});

export default authSlice.reducer;
