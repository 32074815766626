import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
// axios
import axios from 'axios';
import {
  academyApi,
  CreatePostRequest,
  CreatePostResponseResult,
  GetPostByIdResponse,
  GetPostByIdResponseResult,
  SearchPostItem,
  Result,
  SearchPostsResponseResult,
  UpdatePostRequest,
  UpdatePostResponseResult,
  ApplyTagRequest,
  ApplyCategoryRequest,
  SearchPostsResponse
} from 'src/store/apiClient';
import { GetByIdRequest } from 'src/types/GetByIdRequest';
import { PostCategory } from 'src/types/Posts/PostCategory';
import { PostTag } from 'src/types/Posts/PostTag';
import { SearchRequest } from 'src/types/SearchRequest';
import { Status } from 'src/types/Status';

export interface PostState {
  posts: SearchPostsResponse;
  post: GetPostByIdResponse;
  lastSaved: number;
  status: Status;
  error: string | null;
}

const initialState: PostState = {
  posts: null,
  post: null,
  lastSaved: Date.now(),
  status: 'idle',
  error: null
};

export const fetchPosts = createAsyncThunk<
  SearchPostsResponseResult,
  SearchRequest
>('posts/fetchPosts', async (request) => {
  const response = await academyApi.searchPosts(
    request.search,
    request.status?.toString(),
    request.type?.toString(),
    request.take,
    request.skip
  );
  return response.data;
});

export const fetchPostById = createAsyncThunk<
  GetPostByIdResponseResult,
  GetByIdRequest
>('posts/fetchPostById', async ({ id }) => {
  const response = await academyApi.getPostById(id);
  return response.data;
});

export const addNewPost = createAsyncThunk<
  CreatePostResponseResult,
  CreatePostRequest
>('posts/addNewPost', async (request) => {
  const response = await academyApi.createPost(request);
  return response.data;
});

export const editPost = createAsyncThunk<
  UpdatePostResponseResult,
  UpdatePostRequest
>('posts/editPost', async (request) => {
  const response = await academyApi.updatePost(request);
  return response.data;
});

export const deletePost = createAsyncThunk<Result, GetByIdRequest>(
  'posts/deletePost',
  async ({ id }) => {
    const response = await academyApi.deletePost(id);
    return response.data;
  }
);

export const addPostTag = createAsyncThunk<Result, ApplyTagRequest>(
  'posts/applyTag',
  async (request) => {
    const response = await academyApi.applyTag(request);
    return response.data;
  }
);

export const removePostTag = createAsyncThunk<Result, PostTag>(
  'posts/removeTag',
  async ({ postId, tagId }) => {
    const response = await academyApi.removeTag(postId, tagId);
    return response.data;
  }
);

export const addPostCategory = createAsyncThunk<Result, ApplyCategoryRequest>(
  'posts/applyCategory',
  async (request) => {
    const response = await academyApi.applyCategory(request);
    return response.data;
  }
);

export const removePostCategory = createAsyncThunk<Result, PostCategory>(
  'posts/removeCategory',
  async ({ postId, categoryId }) => {
    const response = await academyApi.removeCategory(postId, categoryId);
    return response.data;
  }
);

export const postSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.posts = action.payload.data;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewPost.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewPost.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addNewPost.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';

        state.posts?.items?.push(action.payload.data);
      })
      .addCase(editPost.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editPost.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(editPost.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
        state.lastSaved = Date.now();
      })
      .addCase(fetchPostById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchPostById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.post = action.payload.data;
      })
      .addCase(fetchPostById.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deletePost.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deletePost.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      })
      .addCase(addPostTag.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addPostTag.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addPostTag.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      })
      .addCase(removePostTag.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removePostTag.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(removePostTag.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      })
      .addCase(addPostCategory.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addPostCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addPostCategory.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      })
      .addCase(removePostCategory.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removePostCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(removePostCategory.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      });
  }
});

export default postSlice.reducer;
