import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// axios
import axios from 'axios';
import {
  academyApi,
  CreateAuthorRequest,
  CreateAuthorResponseResult,
  UpdateAuthorRequest,
  UpdateAuthorResponseResult
} from 'src/store/apiClient';

import { Status } from 'src/types/Status';
export interface AuthorState {
  status: Status;
  error: string | null;
}

const initialState: AuthorState = {
  status: 'idle',
  error: null
};


export const addNewAuthor = createAsyncThunk<
  CreateAuthorResponseResult,
  CreateAuthorRequest
>('authors/addNewAuthor', async (initialAuthor) => {
  const response = await academyApi.createAuthor(initialAuthor);
  return response.data;
});

export const updateAuthor = createAsyncThunk<
  UpdateAuthorResponseResult,
  UpdateAuthorRequest
>('authors/updateAuthor', async (request) => {
  const response = await academyApi.updateAuthor(request);
  return response.data;
});


export const authorSlice = createSlice({
  name: 'authors',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(addNewAuthor.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewAuthor.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addNewAuthor.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      })
      .addCase(updateAuthor.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateAuthor.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateAuthor.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      });
  }
});

export default authorSlice.reducer;
