import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// axios
import axios from 'axios';
import {
  academyApi,
  GetAllUsersResponse,
  GetAllUsersResponseResult,
  GetUserByIdResponseResult,
  GetUserByIdResponse,
  Result,
  ApplyRoleRequest,
  RemoveRoleRequest,
  CreateUserRequest,
  CreateUserResponseResult,
  ResetUserPasswordRequest,
  ResetUserPasswordResponseResult,
  GetAllRolesResponseResult
} from 'src/store/apiClient';
import { GetByIdRequest } from 'src/types/GetByIdRequest';
import { SearchRequest } from 'src/types/SearchRequest';
import { Status } from 'src/types/Status';

export interface UserState {
  users: GetAllUsersResponse;
  user: GetUserByIdResponse;
  roles: string[];
  currentRoles: string[];
  status: Status;
  error: string | null;
}

const initialState: UserState = {
  users: null,
  user: null,
  roles: null,
  currentRoles: [],
  status: 'idle',
  error: null
};

export const fetchUsers = createAsyncThunk<
  GetAllUsersResponseResult,
  SearchRequest
>('users/fetchUsers', async (request) => {
  const response = await academyApi.getAllUsers(
    request.search,
    request.take,
    request.skip
  );
  return response.data;
});

export const fetchUserById = createAsyncThunk<
  GetUserByIdResponseResult,
  GetByIdRequest
>('users/fetchUserById', async (request) => {
  const response = await academyApi.getUserById(request.id);
  return response.data;
});

export const fetchRoles = createAsyncThunk<
  GetAllRolesResponseResult,
  any
>('users/fetchRoles', async () => {
  const response = await academyApi.getAllRoles();
  return response.data;
});

export const applyRole = createAsyncThunk<Result, ApplyRoleRequest>(
  'users/applyRole',
  async (request) => {
    const response = await academyApi.applyRole(request);
    return response.data;
  }
);


export const removeRole = createAsyncThunk<Result, RemoveRoleRequest>(
  'users/removeRole',
  async (request) => {
    const response = await academyApi.removeRole(request);
    return response.data;
  }
);

export const createUser = createAsyncThunk<CreateUserResponseResult, CreateUserRequest>(
  'users/createUser',
  async (request) => {
    const response = await academyApi.createUser(request);
    return response.data;
  }
);

export const resetPassword = createAsyncThunk<ResetUserPasswordResponseResult, ResetUserPasswordRequest>(
  'users/resetPassword',
  async (request) => {
    const response = await academyApi.resetUserPassword(request);
    return response.data;
  }
);


export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload.data;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchUserById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.data;
        state.currentRoles = state.user.roles;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchRoles.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.roles = action.payload.data.roles;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(applyRole.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(applyRole.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentRoles.push(action.meta.arg.role)
      })
      .addCase(applyRole.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removeRole.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(removeRole.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentRoles = state.currentRoles.filter(x => x != action.meta.arg.role)

      })
      .addCase(removeRole.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createUser.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default userSlice.reducer;
