import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
// axios
import axios from 'axios';
import {
  academyApi,
  CreateCategoryRequest,
  CreateCategoryResponseResult,
  GetCategoryByIdResponse,
  GetCategoryByIdResponseResult,
  Result,
  SearchCategoriesResponseResult,
  SearchCategoryItem,
  UpdateCategoryRequest,
  UpdateCategoryResponseResult
} from 'src/store/apiClient';
import { GetByIdRequest } from 'src/types/GetByIdRequest';
import { SearchRequest } from 'src/types/SearchRequest';
import { Status } from 'src/types/Status';
export interface CategoryState {
  categories: SearchCategoryItem[];
  category: GetCategoryByIdResponse;
  status: Status;
  error: string | null;
}

const initialState: CategoryState = {
  categories: [],
  category: null,
  status: 'idle',
  error: null
};

export const fetchCategories = createAsyncThunk<
  SearchCategoriesResponseResult,
  SearchRequest
>('categories/fetchCategories', async (request) => {
  const response = await academyApi.searchCategories(
    request.search,
    request.take,
    request.skip
  );
  return response.data;
});

export const fetchCategoryById = createAsyncThunk<
  GetCategoryByIdResponseResult,
  GetByIdRequest
>('categories/fetchCategoryById', async (request) => {
  const response = await academyApi.getCategoryById(request.id);
  return response.data;
});

export const addNewCategory = createAsyncThunk<
  CreateCategoryResponseResult,
  CreateCategoryRequest
>('categories/addNewCategory', async (initialCategory) => {
  const response = await academyApi.createCategory(initialCategory);
  return response.data;
});

export const editCategory = createAsyncThunk<
  UpdateCategoryResponseResult,
  UpdateCategoryRequest
>('categories/editCategory', async (initialCategory) => {
  const response = await academyApi.updateCategory(initialCategory);
  return response.data;
});

export const deleteCategory = createAsyncThunk<Result, GetByIdRequest>(
  'categories/deleteCategory',
  async ({ id }) => {
    const response = await academyApi.deleteCategory(id);
    return response.data;
  }
);

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categoryAdded: (state, action: PayloadAction<CreateCategoryRequest>) => {
      state.categories.push(action.payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCategories.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload.data.items;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCategoryById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCategoryById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.category = action.payload.data;
      })
      .addCase(fetchCategoryById.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewCategory.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addNewCategory.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';

        state.categories.push(action.payload.data);
      })
      .addCase(editCategory.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';

        const index = state.categories.findIndex(
          (x) => x.id === action.payload.data.id
        );
        state.categories[index] = action.payload.data;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';
      });
  }
});

export default categorySlice.reducer;
