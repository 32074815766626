import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// axios
import {
  academyApi,
  CreateTagRequest,
  CreateTagResponseResult,
  SearchTagsItem,
  SearchTagsResponseResult
} from 'src/store/apiClient';
import { SearchRequest } from 'src/types/SearchRequest';
import { Status } from 'src/types/Status';

export interface TagState {
  tags: SearchTagsItem[];
  status: Status;
  error: string | null;
}

const initialState: TagState = {
  tags: [],
  status: 'idle',
  error: null
};

export const fetchTags = createAsyncThunk<
  SearchTagsResponseResult,
  SearchRequest
>('tags/fetchTags', async (request) => {
  const response = await academyApi.searchTags(
    request.search,
    request.take,
    request.skip
  );
  return response.data;
});

export const addNewTag = createAsyncThunk<
  CreateTagResponseResult,
  CreateTagRequest
>('categories/addNewTag', async (initialTag) => {
  const response = await academyApi.createTag(initialTag);
  return response.data;
});

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTags.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tags = action.payload.data.items;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewTag.rejected, (state, action) => {
        console.log('rejected', action);
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewTag.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addNewTag.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.status = 'succeeded';

        state.tags.push(action.payload.data);
      });
  }
});

export default tagSlice.reducer;
