import { configureStore } from '@reduxjs/toolkit';
import postReducer from './slices/posts';
import categoryReducer from './slices/categories';
import tagReducer from './slices/tags';
import authReducer from './slices/auth';
import userReducer from './slices/users';
import authorReducer from './slices/authors';
import eventReducer from './slices/events';

export const store = configureStore({
  reducer: {
    posts: postReducer,
    categories: categoryReducer,
    tags: tagReducer,
    auth: authReducer,
    users: userReducer,
    authors: authorReducer,
    events: eventReducer,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
