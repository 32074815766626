import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import AuthCallback from './content/pages/Status/AuthCallback';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Posts

const ListPosts = Loader(
  lazy(() => import('src/content/pages/Posts/ListPosts'))
);
const NewPost = Loader(lazy(() => import('src/content/pages/Posts/NewPost')));
const EditPost = Loader(lazy(() => import('src/content/pages/Posts/EditPost')));
const DeletePost = Loader(
  lazy(() => import('src/content/pages/Posts/DeletePost'))
);

// Categories

const NewCategory = Loader(
  lazy(() => import('src/content/pages/Categories/NewCategory'))
);
const EditCategory = Loader(
  lazy(() => import('src/content/pages/Categories/EditCategory'))
);
const TableCategories = Loader(
  lazy(() => import('src/content/pages/Categories/ListCategories'))
);
const DeleteCategory = Loader(
  lazy(() => import('src/content/pages/Categories/DeleteCategory'))
);

// Authors

const ListAuthors = Loader(
  lazy(() => import('src/content/pages/Authors/ListAuthors'))
);

const NewAuthor = Loader(
  lazy(() => import('src/content/pages/Authors/NewAuthor'))
);

const EditAuthor = Loader(
  lazy(() => import('src/content/pages/Authors/EditAuthor'))
);


// Events

const ListEvents = Loader(
  lazy(() => import('src/content/pages/Events/ListEvents'))
);

const NewEvent = Loader(
  lazy(() => import('src/content/pages/Events/NewEvent'))
);

const EditEvent = Loader(
  lazy(() => import('src/content/pages/Events/EditEvent'))
);

const DeleteEvent = Loader(
  lazy(() => import('src/content/pages/Events/DeleteEvent'))
);



// Users

const ListUsers = Loader(
  lazy(() => import('src/content/pages/Users/ListUsers'))
);

const EditUser = Loader(lazy(() => import('src/content/pages/Users/EditUser')));

const ManageUserRoles = Loader(
  lazy(() => import('src/content/pages/Users/ManageRoles'))
);

const ResetUserPassword = Loader(
  lazy(() => import('src/content/pages/Users/ResetPassword'))
);

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

// Auth

const LoginPage = Loader(lazy(() => import('src/content/auth/LoginPage')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'callback',
        element: <AuthCallback />
      },
      {
        path: 'auth',
        children: [
          {
            path: '',
            element: <Navigate to="login" replace />
          },
          {
            path: 'login',
            element: <LoginPage />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'posts',
        children: [
          {
            path: '',
            element: <ListPosts />
          },
          {
            path: 'new',
            element: <NewPost />
          },
          {
            path: 'edit/:postId',
            element: <EditPost />
          },
          {
            path: 'delete/:postId',
            element: <DeletePost />
          }
        ]
      },
      {
        path: 'categories',
        children: [
          {
            path: 'new',
            element: <NewCategory />
          },
          {
            path: 'edit/:categoryId',
            element: <EditCategory />
          },
          {
            path: 'delete/:categoryId',
            element: <DeleteCategory />
          },
          {
            path: '',
            element: <TableCategories />
          }
        ]
      },
      {
        path: 'authors',
        children: [
          { path: '', element: <ListAuthors /> },
          {
            path: 'new',
            element: <NewAuthor />
          },
          {
            path: 'edit/:postId',
            element: <EditAuthor />
          }
        ]
      },
      {
        path: 'events',
        children: [
          { path: '', element: <ListEvents /> },
          {
            path: 'new',
            element: <NewEvent />
          },
          {
            path: 'edit/:postId',
            element: <EditEvent />
          },
          {
            path: 'delete/:postId',
            element: <DeleteEvent />
          }
        ]
      },
      {
        path: 'users',
        children: [
          { path: '', element: <ListUsers /> },
          { path: 'edit/:userId', element: <EditUser /> },
          { path: 'edit/:userId/roles', element: <ManageUserRoles /> },
          { path: 'edit/:userId/resetpassword', element: <ResetUserPassword /> }
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
